exports.components = {
  "component---src-channels-application-single-js": () => import("./../../../src/channels/application-single.js" /* webpackChunkName: "component---src-channels-application-single-js" */),
  "component---src-channels-case-studies-single-js": () => import("./../../../src/channels/case-studies-single.js" /* webpackChunkName: "component---src-channels-case-studies-single-js" */),
  "component---src-templates-archee-404-js": () => import("./../../../src/templates/archee404.js" /* webpackChunkName: "component---src-templates-archee-404-js" */),
  "component---src-templates-archee-aplikacje-js": () => import("./../../../src/templates/archeeAplikacje.js" /* webpackChunkName: "component---src-templates-archee-aplikacje-js" */),
  "component---src-templates-archee-case-studies-js": () => import("./../../../src/templates/archeeCaseStudies.js" /* webpackChunkName: "component---src-templates-archee-case-studies-js" */),
  "component---src-templates-archee-components-js": () => import("./../../../src/templates/archeeComponents.js" /* webpackChunkName: "component---src-templates-archee-components-js" */),
  "component---src-templates-archee-document-flow-js": () => import("./../../../src/templates/archeeDocumentFlow.js" /* webpackChunkName: "component---src-templates-archee-document-flow-js" */),
  "component---src-templates-archee-get-started-js": () => import("./../../../src/templates/archeeGetStarted.js" /* webpackChunkName: "component---src-templates-archee-get-started-js" */),
  "component---src-templates-archee-kontakt-js": () => import("./../../../src/templates/archeeKontakt.js" /* webpackChunkName: "component---src-templates-archee-kontakt-js" */),
  "component---src-templates-archee-management-center-js": () => import("./../../../src/templates/archeeManagementCenter.js" /* webpackChunkName: "component---src-templates-archee-management-center-js" */),
  "component---src-templates-archee-o-nas-js": () => import("./../../../src/templates/archeeONas.js" /* webpackChunkName: "component---src-templates-archee-o-nas-js" */),
  "component---src-templates-archee-polityka-prywatnosci-js": () => import("./../../../src/templates/archeePolitykaPrywatnosci.js" /* webpackChunkName: "component---src-templates-archee-polityka-prywatnosci-js" */),
  "component---src-templates-archee-strona-glowna-js": () => import("./../../../src/templates/archeeStronaGlowna.js" /* webpackChunkName: "component---src-templates-archee-strona-glowna-js" */)
}

